import {
    ConstrainMode,
    DetailsList,
    DetailsListLayoutMode,
    IColumn,
    ICommandBarItemProps,
    IPanelStyles,
    Selection,
    mergeStyles,
    Panel,
    Persona,
    PersonaSize,
    SelectionMode,
    PanelType,
    DefaultButton,
    SearchBox,
} from "@fluentui/react";
import React from "react";
import { UserType } from "../../helpers/enums";
import { IPatient } from "../../interfaces/IPatient";
import { IUser } from "../../interfaces/IUser";
import { BrumCommandBar } from "../BrumCommandBar";
import { NoItemsFound } from "../NoItemsFound";
import { SelectUserPanel } from "./SelectUserPanel";
import { isMobile } from "react-device-detect";
import { LanguageContext } from "../../helpers/LocalizationModule";

const panelStyles: Partial<IPanelStyles> = {
    content: {
        overflowX: "hidden",
        touchAction: "true",
        height: "calc(100vh - 124px);",
        paddingLeft: 12,
        paddingRight: 12,
        paddingBottom: 0,
    },
};

export interface IManageUsersPanelProps {
    isOpen: boolean;
    dismissPanel: () => void;
    userType: UserType;
    patient: IPatient;
    setPatient: (patient: IPatient) => void;
}

export const ManageUsersPanel: React.FunctionComponent<IManageUsersPanelProps> = ({ isOpen, dismissPanel, userType, patient, setPatient }: IManageUsersPanelProps) => {
    const languageStrings = React.useContext(LanguageContext);

    const [commandBarItems, setCommandBarItems] = React.useState<ICommandBarItemProps[]>([]);
    const [selectedItem, setSelectedItem] = React.useState<any>();
    const [isSelectUserPanelOpen, setIsSelectUserPanelOpen] = React.useState(false);

    const [filteredUsers, setFilteredUsers] = React.useState<IUser[]>([]);
    const [searchText, setSearchText] = React.useState<string>("");

    const _selection = React.useRef(
        new Selection({
            onSelectionChanged: () => getSelectionDetails(),
        })
    );

    const getSelectionDetails = () => {
        const filteredSelectedItems = _selection.current.getSelection().filter((x) => x);

        if (filteredSelectedItems?.length === 1) {
            let selectedItem: any = filteredSelectedItems[0];
            setSelectedItem(selectedItem);
        } else {
            setSelectedItem(null);
        }
    };

    React.useEffect(() => {
        if (searchText) {
            setFilteredUsers(
                patient.users
                    ?.filter((x) => x.typeId === userType)
                    .filter((x) => x.firstName?.toLowerCase().includes(searchText.toLowerCase()) || x.lastName?.toLowerCase().includes(searchText.toLowerCase())) ?? []
            );
        } else {
            setFilteredUsers(patient.users?.filter((x) => x.typeId === userType) ?? []);
        }
    }, [patient, searchText, userType]);

    React.useEffect(() => {
        setCommandBarItems([
            {
                key: "Add",
                text: languageStrings.Add,
                disabled: selectedItem,
                iconProps: { iconName: "Add" },
                onClick: () => {
                    setIsSelectUserPanelOpen(true);
                },
            },
            {
                key: "Delete",
                text: languageStrings.Delete,
                disabled: !selectedItem,
                iconProps: { iconName: "Delete" },
                onClick: () => {
                    setPatient({ ...patient, users: patient.users.filter((x) => x.id !== selectedItem.id) });
                    setSelectedItem(undefined);
                },
            },
        ]);
    }, [selectedItem]);

    const columns: IColumn[] = [
        {
            key: "displayName",
            name: languageStrings.Name,
            fieldName: "displayName",
            data: "string",
            minWidth: 100,
            maxWidth: 350,
            isResizable: true,
            onRender: (patient: IPatient) => {
                return (
                    <div className="hover:cursor-pointer">
                        <Persona
                            imageUrl={patient.profileImage && `data:image/*;base64,${patient.profileImage}`}
                            text={`${patient.firstName} ${patient.lastName}`}
                            size={PersonaSize.size40}
                            onClick={() => { }}
                        />
                    </div>
                );
            },
        },
    ];

    const getHeaderText = () => {
        let headerText = "";

        switch (userType) {
            case UserType.Medewerker:
                headerText = languageStrings.ManageMedewerkers;
                break;

            case UserType.Gaurdian:
                headerText = languageStrings.ManageParents;
                break;

            case UserType.Caretaker:
                headerText = languageStrings.ManageCaretakers;
                break;

            default:
                break;
        }

        return headerText;
    };

    const userSelected = (user: IUser) => {
        setIsSelectUserPanelOpen(false);
        setPatient({ ...patient, users: [...patient.users, user] });
    };

    const onRenderFooterContent = React.useCallback(
        () => (
            <div className="text-right">
                <DefaultButton onClick={dismissPanel}>{languageStrings.Done}</DefaultButton>
            </div>
        ),
        [dismissPanel]
    );

    const searchChanged = (value: string) => {
        setSearchText(value);
    };

    return (
        <Panel
            headerText={getHeaderText()}
            isOpen={isOpen}
            onDismiss={dismissPanel}
            closeButtonAriaLabel="Close"
            styles={panelStyles}
            type={PanelType.custom}
            customWidth={`${isMobile ? "100%" : "500px"}`}
            onRenderFooterContent={onRenderFooterContent}
            isFooterAtBottom={true}
            allowTouchBodyScroll
        >
            <div>
                <BrumCommandBar commandBarItems={commandBarItems} />
                <div className="py-2">
                    <SearchBox placeholder={languageStrings.Search} underlined onChange={(event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => searchChanged(newValue ?? "")} />
                </div>
                {filteredUsers.length > 0 && (
                    <div className="">
                        <DetailsList
                            items={filteredUsers ?? []}
                            columns={columns}
                            selectionMode={SelectionMode.single}
                            layoutMode={DetailsListLayoutMode.justified}
                            constrainMode={ConstrainMode.unconstrained}
                            selection={_selection.current}
                            checkboxCellClassName={mergeStyles({
                                display: "flex",
                                alignItems: "center",
                            })}
                            onShouldVirtualize={() => {
                                return false;
                            }}
                        />
                    </div>
                )}
                {filteredUsers.length === 0 && <NoItemsFound />}
            </div>

            {isSelectUserPanelOpen && userType && (
                <SelectUserPanel
                    isOpen={isSelectUserPanelOpen}
                    userType={userType}
                    dismissPanel={() => {
                        setIsSelectUserPanelOpen(false);
                    }}
                    itemSelected={userSelected}
                    patient={patient}
                />
            )}
        </Panel>
    );
};
