import { DefaultButton, Dialog, DialogFooter, DialogType, Icon, Spinner, SpinnerSize, TextField, TooltipHost } from "@fluentui/react";
import React from "react";
import { LanguageContext } from "../helpers/LocalizationModule";
import { updatePassword } from "../services/userService";
const passwordValidator = require("password-validator");

export interface IChangePasswordProps {
    hideDialog: boolean;
    dismissDialog: () => void;
    userId: number;
}

export const ChangePassword: React.FunctionComponent<IChangePasswordProps> = ({ hideDialog, dismissDialog, userId }: IChangePasswordProps) => {
    const languageStrings = React.useContext(LanguageContext);

    const [password, setPassword] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState<string>("");
    const [isSaving, setIsSaving] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");

    const passwordSchema = new passwordValidator();
    passwordSchema.is().min(6, languageStrings.ValidationErrorPasswordTooSHort);
    passwordSchema.has().uppercase(1, languageStrings.ValidationErrorPasswordMustHaveUppercase);
    passwordSchema.has().digits(1, languageStrings.ValidationErrorPasswordMustHaveDigits);

    const dialogContentProps = {
        type: DialogType.normal,
        title: languageStrings.ChangePassword,
        closeButtonAriaLabel: "Close",
        subText: languageStrings.EnterNewPassword,
    };

    React.useEffect(() => {
        if (!hideDialog) {
            setPassword("");
            setConfirmPassword("");
            setErrorMessage("");
        }
    }, [hideDialog]);

    const onChange = async () => {
        const passwordErrors = passwordSchema.validate(password, { details: true });

        if (passwordErrors && passwordErrors.length > 0) {
            setErrorMessage(passwordErrors[0].message);
            return;
        }

        if (password !== confirmPassword) {
            setErrorMessage(languageStrings.PasswordsDoNotMatch);
            return;
        }

        setIsSaving(true);
        await updatePassword(userId.toString(), password);
        setIsSaving(false);
        dismissDialog();
    };

    const getPasswordTooltip = () => {
        return (
            <ul>
                <li> - {languageStrings.ValidationErrorPasswordTooSHort}</li>
                <li> - {languageStrings.ValidationErrorPasswordMustHaveUppercase}</li>
                <li> - {languageStrings.ValidationErrorPasswordMustHaveDigits}</li>
            </ul>
        );
    };

    return (
        <>
            <Dialog hidden={hideDialog} onDismiss={dismissDialog} dialogContentProps={dialogContentProps}>
                <form autoComplete="off">
                    <div className="flex">
                        <div className="grow">
                            <TextField
                                value={password}
                                type="password"
                                placeholder={languageStrings.Password}
                                onChange={(e, newValue) => {
                                    setPassword(newValue ?? "");
                                }}
                                canRevealPassword
                                errorMessage={errorMessage}
                                name="do-not-autofill"
                                id="do-not-autofill"
                                autoComplete="do-not-autofill"
                            />
                        </div>
                        <div className="flex-none text-right pl-2 pt-1">
                            <TooltipHost content={getPasswordTooltip()}>
                                <Icon className="text-lg theme-color-text hover:cursor-help" iconName={"Info"}></Icon>
                            </TooltipHost>
                        </div>
                    </div>
                    <div className="flex mt-2 pb-2">
                        <div className="grow">
                            <TextField
                                value={confirmPassword}
                                type="password"
                                placeholder={languageStrings.ConfirmPassword}
                                onChange={(e, newValue) => {
                                    setConfirmPassword(newValue ?? "");
                                }}
                                canRevealPassword
                                name="do-not-autofill"
                                id="do-not-autofill"
                                autoComplete="do-not-autofill"
                            />
                        </div>
                        <div className="flex-none w-[26px]">
                        </div>
                    </div>
                </form>
                <DialogFooter>
                    <DefaultButton onClick={dismissDialog} text={languageStrings.Cancel} disabled={isSaving} />
                    <DefaultButton onClick={onChange} text={languageStrings.Save} disabled={isSaving}>
                        {isSaving && <Spinner size={SpinnerSize.small} styles={{ root: { marginLeft: 5 } }}></Spinner>}
                    </DefaultButton>
                </DialogFooter>
            </Dialog>
        </>
    );
};
