import { ActionButton, DefaultButton, Spinner, SpinnerSize, Stack, StackItem, TextField } from "@fluentui/react";
import axios from "axios";
import React from "react";
import { AppPage } from "../helpers/enums";
import { LanguageContext } from "../helpers/LocalizationModule";
import { IAuthRequest } from "../interfaces/IAuthRequest";
import { IAuthResponse } from "../interfaces/IAuthResponse";
import { ITwoFactorCodeAuthRequest } from "../interfaces/ITwoFactorCodeAuthRequest";
import { useAppDispatch } from "../redux/hooks";
import { setCurrentPage } from "../redux/slices/navigationSlice";
import { setUser } from "../redux/slices/userSlice";
import { authenticate, refreshUserAccessToken, twoFactorCodeAuth, updateLastActivity } from "../services/userService";
import { StorageHelper } from "../helpers/StorageHelper";

const termsText: string = `BrumBrum is verantwoordelijk voor de verwerking van persoonsgegevens zoals weergegeven in deze privacyverklaring. BrumBrum verwerkt je persoonsgegevens doordat je gebruik maakt van onze diensten en/of doordat je diensten aan ons levert en/of omdat je deze gegevens zelf aan ons verstrekt. Hieronder vind je een overzicht van de persoonsgegevens die wij eventueel van jou verwerken:

	Voor- en achternaam
	Geslacht
	Geboortedatum
	Geboorteplaats
	Adresgegevens
	Naam, adres, geboortedatum familie leden
	Telefoonnummer
	E-mailadres
	Bankrekeningnummer

Daarnaast verwerkt BrumBrum eventueel de volgende bijzondere en/of gevoelige persoonsgegevens van jou:

	godsdienst of levensovertuiging
	gezondheid en/of medische gegevens
	burgerservicenummer (BSN)
	gegevens van personen jonger dan 16 jaar
	Gegevens van personen jonger dan 16 jaar worden enkel verwerkt indien deze door ons verifieerbaar worden aangeleverd door de ouders of voogd. Als je ervan overtuigd bent dat wij zonder die toestemming persoonlijke gegevens hebben verzameld over een minderjarige, neem dan contact met ons op via bob@brumbrum.nl, dan verwijderen wij deze informatie. BrumBrum verwerkt jouw persoonsgegevens voor de volgende doelen:

Wij verwerken persoonsgegevens in verband met bereikbaarheid van ouders, verzorger, opa’s, oma’s, enz in geval van ziekte van het kind of andere urgente zaken.
Wij verwerken persoonsgegevens om onze dagbehandelingswerkzaamheden naar behoren te kunnen uitvoeren.
Wij verwerken ook persoonsgegevens als wij hier wettelijk toe verplicht zijn. Hierbij kun je denken aan controle of dat je wel recht hebt op zorg en om deze zorg te kunnen declareren.

BrumBrum neemt niet op basis van geautomatiseerde verwerkingen besluiten over zaken die (aanzienlijke) gevolgen kunnen hebben voor personen. BrumBrum maakt geen gebruikt van cookies of vergelijkbare technieken. BrumBrum bewaart je persoonsgegevens niet langer dan strikt nodig is om de doelen te realiseren waarvoor je gegevens worden verzameld. Wij hanteren de volgende bewaartermijnen voor de volgende (categorieën) van persoonsgegevens:

Persoonsgegevens:

Persoonsgegevens worden bewaard zolang je gebruik maakt van onze diensten en/of doordat je diensten aan ons levert. Op het moment dat je geen gebruik meer maakt van onze diensten en/of doordat je geen diensten meer aan ons levert zullen we je persoonsgegevens 5 jaar bewaren, tenzij je aangeeft dat ze eerder verwijderd moeten worden.

Bijzondere en/of gevoelige persoonsgegevens:

Bijzondere en/of gevoelige persoonsgegevens worden in de vorm een dossier 15 jaar bewaard. Op het moment dat je geen gebruik meer maakt van onze diensten en/of doordat je geen diensten meer aan ons levert zullen we je bijzondere en/of gevoelige persoonsgegevens binnen 1 maand verwijderen met uitzondering van je dossier. Je dossier wordt door ons 15 jaar bewaard.

Delen van persoonsgegevens met derden. BrumBrum zal jou persoonsgegevens uitsluitend verstrekken aan derden indien:

BrumBrum een bewerkersovereenkomst heeft met instanties/ therapeuten/ bedrijven en dit nodig is voor de uitvoering van onze overeenkomst met jou.
Om te voldoen aan een wettelijke verplichting.
Dit anders met jou is afgesproken.

Gegevens inzien, aanpassen of verwijderen:

Je hebt het recht om je persoonsgegevens in te zien, te corrigeren of te verwijderen. Daarnaast heb je het recht om je eventuele toestemming voor de gegevensverwerking in te trekken of bezwaar te maken tegen de verwerking van jouw persoonsgegevens door BrumBrum en heb je het recht op gegevensoverdraagbaarheid. Dat betekent dat je bij ons een verzoek kan indienen om de persoonsgegevens die wij van jou beschikken in een computerbestand naar jou of een andere, door jou genoemde organisatie, te sturen.

Je kunt een verzoek tot inzage, correctie, verwijdering, gegevensoverdraging van je persoonsgegevens of verzoek tot intrekking van je toestemming of bezwaar op de verwerking van jouw persoonsgegevens sturen naar bob@brumbrum.nl. Om er zeker van te zijn dat het verzoek tot inzage door jou is gedaan, kunnen we je vragen om je te legitimeren We reageren zo snel mogelijk, maar binnen vier weken, op jouw verzoek.

BrumBrum wil je er tevens op wijzen dat je de mogelijkheid hebt om een klacht in te dienen bij de nationale toezichthouder, de Autoriteit Persoonsgegevens. Dat kan via de volgende link: https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons

Hoe wij persoonsgegevens beveiligen:

BrumBrum neemt de bescherming van jouw gegevens serieus en neemt passende maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als jij het idee hebt dat jouw gegevens toch niet goed beveiligd zijn of er aanwijzingen zijn van misbruik, neem dan contact op met onze klantenservice of via bob@brumbrum.nl`;

export const LoginPage: React.FunctionComponent = () => {
    const languageStrings = React.useContext(LanguageContext);
    const dispatch = useAppDispatch();

    const [authReq, setAuthReq] = React.useState<IAuthRequest>({ email: "", password: "" });
    const [authReqCode, setAuthReqCode] = React.useState<ITwoFactorCodeAuthRequest>({ userId: undefined, twoFactorAuthCode: "" });
    const [isBusy, setIsBusy] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [userLocal, setUserLocal] = React.useState<IAuthResponse>();
    const [showTermsMessage, setShowTermsMessage] = React.useState(false);

    const onLogin = async (e: any) => {
        e.preventDefault();

        try {
            setIsBusy(true);
            const userResult = await authenticate(authReq);
            axios.defaults.headers.common["Authorization"] = `Bearer ${userResult.token}`;
            updateLastActivity(userResult.id);
            if (!userResult.twoFactorAuthEnabled) {
                setErrorMessage("");
                dispatch(setUser(userResult));
                StorageHelper.setItem("user", JSON.stringify(userResult));

                if (userResult.isFirstSignIn) {
                    setShowTermsMessage(true);
                } else {
                    dispatch(setCurrentPage(AppPage.HomePage));
                }

                setIsBusy(false);
            } else {
                setErrorMessage("");
                setUserLocal(userResult);
                setIsBusy(false);
                setAuthReqCode({ ...authReqCode, userId: userResult.id });
            }
        } catch (error: any) {
            console.error(error);
            if (error?.response?.data?.message) {
                if (error?.response?.data?.message === "Username or password is incorrect") {
                    setErrorMessage(languageStrings.ErrorUsernamePassword);
                } else {
                    setErrorMessage(error?.response?.data?.message);
                }
            } else {
                setErrorMessage(languageStrings.ErrorOccured);
            }

            setIsBusy(false);
        }
    };

    const onResetPassword = async (e: any) => {
        e.preventDefault();
        dispatch(setCurrentPage(AppPage.ResetPassword));
    };

    const onLoginCode = React.useCallback(
        async (e: any) => {
            e.preventDefault();

            try {
                setIsBusy(true);
                const results = await twoFactorCodeAuth(authReqCode);

                dispatch(setUser(userLocal));
                StorageHelper.setItem("user", JSON.stringify(userLocal));
                if (userLocal?.isFirstSignIn) {
                    setShowTermsMessage(true);
                } else {
                    dispatch(setCurrentPage(AppPage.HomePage));
                }

                setIsBusy(false);
            } catch (error: any) {
                setErrorMessage(error?.response?.data?.message ? error?.response?.data?.message : languageStrings.ErrorOccured);
                setIsBusy(false);
            }
        },
        [userLocal, authReqCode]
    );

    const onAcceptTerms = async () => {
        if (!userLocal) {
            const userStr = StorageHelper.getItem("user");
            if (userStr) {
                const user: IAuthResponse = JSON.parse(userStr);
                refreshUserAccessToken(user);
                updateLastActivity(user.id);
            }
        }

        setShowTermsMessage(false)
        dispatch(setCurrentPage(AppPage.HomePage));
    }

    return (
        <div className="theme-color md:bg-white h-[100%] w-full text-center flex">
            <div className="m-auto p-6 md:shadow-2xl theme-color">
                <div>
                    <img alt="BrumBrum" src="/logo.png" />
                </div>
                {errorMessage && <div className="bg-red-300 text-gray-800 mb-4">{errorMessage}</div>}
                {!userLocal && !showTermsMessage && (
                    <form onSubmit={() => onLogin}>
                        <div className="mb-2 w-full">
                            <TextField
                                value={authReq.email}
                                required
                                placeholder={languageStrings.Email}
                                type="email"
                                onChange={(e, newValue) => {
                                    setAuthReq({ ...authReq, email: newValue ? newValue : "" });
                                }}
                            />
                        </div>
                        <div className="mb-2">
                            <TextField
                                value={authReq.password}
                                required
                                placeholder={languageStrings.Password}
                                type="password"
                                canRevealPassword
                                onChange={(e, newValue) => {
                                    setAuthReq({ ...authReq, password: newValue ? newValue : "" });
                                }}
                            />
                        </div>
                        <div className="mx-16 mt-6">
                            <Stack>
                                <StackItem>
                                    <DefaultButton type="submit" text={languageStrings.Continue} className="bg-black text-white hover:bg-black hover:text-white" onClick={(e) => onLogin(e)}>
                                        {isBusy && <Spinner size={SpinnerSize.small} styles={{ root: { marginLeft: 5 } }}></Spinner>}
                                    </DefaultButton>
                                </StackItem>
                                <StackItem>
                                    <ActionButton className="h-5 mt-4 text-white hover:text-white" onClick={(e) => onResetPassword(e)}>{languageStrings.ForgottenPassword}</ActionButton>
                                </StackItem>
                            </Stack>
                        </div>
                    </form>
                )}
                {userLocal && !showTermsMessage && (
                    <form onSubmit={() => onLogin}>
                        <div className="mb-2">
                            <TextField
                                value={authReqCode.twoFactorAuthCode}
                                required
                                placeholder={languageStrings.SecurityCode}
                                onChange={(e, newValue) => {
                                    setAuthReqCode({ ...authReqCode, twoFactorAuthCode: newValue ? newValue : "" });
                                }}
                            />
                        </div>
                        <div className="mx-16 mb-2 mt-6">
                            <DefaultButton type="submit" text={languageStrings.SignIn} className="bg-black text-white hover:bg-black hover:text-white" onClick={(e) => onLoginCode(e)}>
                                {isBusy && <Spinner size={SpinnerSize.small} styles={{ root: { marginLeft: 5 } }}></Spinner>}
                            </DefaultButton>
                        </div>
                    </form>
                )}
                {showTermsMessage &&
                    <>
                        <div className="mb-2">
                            Voorwaarden & Privacyverklaring
                        </div>
                        <div className="mb-2">
                            <TextField multiline rows={15} borderless defaultValue={termsText} />
                        </div>
                        <div className="mb-2">
                            {languageStrings.AcceptTerms}
                        </div>
                        <div className="mx-16 mb-2">
                            <DefaultButton type="submit" text={languageStrings.Yes} className="bg-black text-white hover:bg-black hover:text-white" onClick={() => onAcceptTerms()}>
                                {isBusy && <Spinner size={SpinnerSize.small} styles={{ root: { marginLeft: 5 } }}></Spinner>}
                            </DefaultButton>
                        </div>
                    </>
                }
            </div>
        </div>
    );
};
