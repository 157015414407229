import { ActionButton, DefaultButton, Icon, Spinner, SpinnerSize, Stack, StackItem, TextField, TooltipHost } from "@fluentui/react";
import React from "react";
import axios from "axios";
import { LanguageContext } from "../helpers/LocalizationModule";
import { useAppDispatch } from "../redux/hooks";
import { IPasswordResetRequest } from "../interfaces/IPasswordResetRequest";
import { passwordReset, resetPasswordCodeAuth, resetPasswordUserAuth, updateLastActivity, updatePassword } from "../services/userService";
import { setCurrentPage } from "../redux/slices/navigationSlice";
import { AppPage } from "../helpers/enums";
import { IResetPasswordCodeAuthRequest, IResetPasswordCodeAuthResponse } from "../interfaces/IResetPasswordCodeAuth";
import { StorageHelper } from "../helpers/StorageHelper";
import { setUser } from "../redux/slices/userSlice";
import PasswordValidator from "password-validator";

enum ResetMode {
    account,
    otp,
    confirm
}

export const ResetPassword: React.FunctionComponent = () => {
    const languageStrings = React.useContext(LanguageContext);
    const dispatch = useAppDispatch();

    const [resetMode, setResetMode] = React.useState<ResetMode>(ResetMode.account);

    const [passwordResetReq, setPasswordResetReq] = React.useState<IPasswordResetRequest>({ email: "" });
    const [otpCodeAuth, setOtpCodeAuth] = React.useState<IResetPasswordCodeAuthRequest | undefined>();

    const [password, setPassword] = React.useState<string>("");
    const [confirmPassword, setConfirmPassword] = React.useState<string>("");

    const [isBusy, setIsBusy] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");

    const userId = React.useRef<number>();

    const passwordSchema = new PasswordValidator();
    passwordSchema.is().min(6, languageStrings.ValidationErrorPasswordTooSHort);
    passwordSchema.has().uppercase(1, languageStrings.ValidationErrorPasswordMustHaveUppercase);
    passwordSchema.has().digits(1, languageStrings.ValidationErrorPasswordMustHaveDigits);

    const passwordTooltip = React.useMemo(() => {
        return (
            <ul>
                <li> - {languageStrings.ValidationErrorPasswordTooSHort}</li>
                <li> - {languageStrings.ValidationErrorPasswordMustHaveUppercase}</li>
                <li> - {languageStrings.ValidationErrorPasswordMustHaveDigits}</li>
            </ul>
        );
    }, []);

    const onRequestReset = async (e: any) => {
        e.preventDefault();

        setErrorMessage("");
        setIsBusy(true);
        try {
            if (resetMode === ResetMode.account) {
                await passwordReset(passwordResetReq);
                setResetMode(ResetMode.otp);
            }
            else if (resetMode === ResetMode.otp) {
                if (otpCodeAuth) {
                    const otpCodeAuthResponse: IResetPasswordCodeAuthResponse = await resetPasswordCodeAuth(otpCodeAuth);
                    if (otpCodeAuthResponse) {
                        userId.current = otpCodeAuthResponse.userId;
                        axios.defaults.headers.common["Authorization"] = `Bearer ${otpCodeAuthResponse.jwtBearerToken}`;
                        setResetMode(ResetMode.confirm);
                    }
                }
            }
            else if (resetMode === ResetMode.confirm) {
                const passwordErrors = passwordSchema.validate(password, { details: true });

                if (Array.isArray(passwordErrors) && passwordErrors.length > 0) {
                    setErrorMessage(passwordErrors[0].message);
                    setIsBusy(false);
                    return;
                }

                if (userId.current && password === confirmPassword) {
                    await updatePassword(userId.current.toString(), confirmPassword);

                    const userAuthResult = await resetPasswordUserAuth({ email: passwordResetReq.email, password: confirmPassword });
                    axios.defaults.headers.common["Authorization"] = `Bearer ${userAuthResult.token}`;

                    updateLastActivity(userAuthResult.id);

                    StorageHelper.setItem("user", JSON.stringify(userAuthResult));

                    dispatch(setUser(userAuthResult));
                    dispatch(setCurrentPage(AppPage.HomePage));
                }
                else {
                    setErrorMessage(languageStrings.PasswordsDoNotMatch);
                }
            }
        } catch (error: any) {
            setErrorMessage(error?.response?.data?.message ? error?.response?.data?.message : languageStrings.ErrorOccured);
        }
        setIsBusy(false);
    };

    return (
        <div className="theme-color md:bg-white h-[100%] w-full text-center flex">
            <div className="m-auto p-6 md:shadow-2xl theme-color">
                <div>
                    <img alt="BrumBrum" src="/logo.png" />
                </div>
                {errorMessage && <div className="bg-red-300 text-gray-800 mb-4">{errorMessage}</div>}
                <form onSubmit={() => onRequestReset}>
                    <div className="mb-2 w-full">
                        {resetMode === ResetMode.account && <TextField
                            value={passwordResetReq.email}
                            required
                            placeholder={languageStrings.Email}
                            type="email"
                            onChange={(e, newValue) => {
                                setPasswordResetReq({ email: newValue ? newValue : "" });
                            }}
                        />}
                        {resetMode === ResetMode.otp && <TextField
                            required
                            placeholder={languageStrings.SecurityCode}
                            onChange={(e, newValue) => {
                                setOtpCodeAuth({ email: passwordResetReq.email, twoFactorAuthCode: newValue ? newValue : "" });
                            }}
                        />}
                        {resetMode === ResetMode.confirm && <>
                            <div className="flex">
                                <div className="grow">
                                    <TextField
                                        value={password}
                                        type="password"
                                        placeholder={languageStrings.Password}
                                        onChange={(e, newValue) => {
                                            setPassword(newValue ?? "");
                                        }}
                                        canRevealPassword
                                        name="do-not-autofill"
                                        id="do-not-autofill"
                                        autoComplete="do-not-autofill"
                                    />
                                </div>
                                <div className="flex-none pl-2 pt-1">
                                    <TooltipHost content={passwordTooltip}>
                                        <Icon className="text-lg hover:cursor-help" iconName={"Info"}></Icon>
                                    </TooltipHost>
                                </div>
                            </div>
                            <div className="flex mt-2">
                                <div className="grow">
                                    <TextField
                                        value={confirmPassword}
                                        type="password"
                                        placeholder={languageStrings.ConfirmPassword}
                                        onChange={(e, newValue) => {
                                            setConfirmPassword(newValue ?? "");
                                        }}
                                        canRevealPassword
                                        name="do-not-autofill"
                                        id="do-not-autofill"
                                        autoComplete="do-not-autofill"
                                    />
                                </div>
                                <div className="flex-none w-[26px]">
                                </div>
                            </div>
                        </>}
                    </div>
                    <div className="mx-16 mt-6">
                        <Stack>
                            <StackItem>
                                <DefaultButton type="submit" text={languageStrings.Continue} className="bg-black text-white hover:bg-black hover:text-white" onClick={(e) => onRequestReset(e)}>
                                    {isBusy && <Spinner size={SpinnerSize.small} styles={{ root: { marginLeft: 5 } }}></Spinner>}
                                </DefaultButton>
                            </StackItem>
                            <StackItem>
                                <ActionButton className="h-5 mt-4 text-white hover:text-white" onClick={() => dispatch(setCurrentPage(AppPage.LoginPage))}>{languageStrings.BackToLogin}</ActionButton>
                            </StackItem>
                        </Stack>
                    </div>
                </form>
            </div>
        </div>
    );
};