import React from "react";
import "./App.css";
import { Layout } from "./_components/Layout";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { AppPage, UserType } from "./helpers/enums";
import { HomePage } from "./_pages/HomePage";
import { InstaPage } from "./_pages/InstaPage";
import { WorkDocumentPage } from "./_pages/WorkDocumentPage";
import { ReportPage } from "./_pages/ReportsPage";
import { setCurrentPage } from "./redux/slices/navigationSlice";
import { LoginPage } from "./_pages/LoginPage";
import { setUser } from "./redux/slices/userSlice";
import { AdminPage } from "./_pages/admin/AdminPage";
import { loadTheme, PartialTheme } from "@fluentui/react";
import { LanguageContext, LocalizationModule } from "./helpers/LocalizationModule";
import { AdminPatientsPage } from "./_pages/admin/AdminPatientsPage";
import { AdminUsersPage } from "./_pages/admin/AdminUsersPage";
import { IAuthResponse } from "./interfaces/IAuthResponse";
import { refreshUserAccessToken, updateLastActivity } from "./services/userService";
import { ArchivePage } from "./_pages/ArchivePage";
import { BrumGeneralPage } from "./_pages/BrumGeneralPage";
import { StorageHelper } from "./helpers/StorageHelper";
import axios from "axios";
import { MethodolgyPage } from "./_pages/MethodolgyPage";
import { ResetPassword } from "./_pages/ResetPassword";

const appTheme: PartialTheme = {
    palette: {
        themePrimary: "purple",
    },
};

loadTheme(appTheme);

function App() {
    const languageStrings = LocalizationModule.getLocaleStrings();
    const dispatch = useAppDispatch();

    const currentPage = useAppSelector((state) => state.navigation.currentPage);
    const user = useAppSelector((state) => state.user.user);

    React.useEffect(() => {
        (async () => {
            const userLocal = StorageHelper.getItem("user");
            if (userLocal) {
                const user: IAuthResponse = JSON.parse(userLocal);
                axios.defaults.headers.common["Authorization"] = `Bearer ${user.token}`;

                user.patients = undefined;
                if (user.isFirstSignIn) {
                    dispatch(setCurrentPage(AppPage.LoginPage));
                } else {
                    await refreshUserAccessToken(user);
                    updateLastActivity(user.id);
                    dispatch(setUser(user));
                    dispatch(setCurrentPage(AppPage.HomePage));
                }
            } else {
                dispatch(setCurrentPage(AppPage.LoginPage));
            }
        })();
    }, [dispatch]);

    return (
        <LanguageContext.Provider value={languageStrings}>
            {currentPage !== AppPage.None && currentPage !== AppPage.LoginPage && currentPage !== AppPage.ResetPassword && (
                <Layout>
                    {currentPage === AppPage.HomePage && user && <HomePage />}
                    {currentPage === AppPage.InstaPage && user && <InstaPage />}
                    {currentPage === AppPage.WorkDocumentPage && user && <WorkDocumentPage />}
                    {currentPage === AppPage.ReportPage && user && <ReportPage />}
                    {currentPage === AppPage.ArchivePage && user && <ArchivePage />}
                    {currentPage === AppPage.BrumGeneralPage && user && <BrumGeneralPage />}
                    {currentPage === AppPage.AdminPage && user && <AdminPage />}
                    {currentPage === AppPage.AdminChildrenPage && user && <AdminPatientsPage />}
                    {currentPage === AppPage.AdminUsersPage && user && <AdminUsersPage />}
                    {currentPage === AppPage.Methodology && user && <MethodolgyPage />}
                </Layout>
            )}
            {currentPage === AppPage.LoginPage && <LoginPage />}
            {currentPage === AppPage.ResetPassword && <ResetPassword />}
        </LanguageContext.Provider>
    );
}

export default App;
