import { ActionButton, DefaultButton, IPanelStyles, IPersonaSharedProps, Label, Panel, PanelType, Persona, PersonaSize } from "@fluentui/react";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { isMobile } from "react-device-detect";
import { LanguageContext } from "../../helpers/LocalizationModule";
import { AppPage, UserType } from "../../helpers/enums";
import { resetInsta } from "../../redux/slices/instaSlice";
import { resetNav, setCurrentPage } from "../../redux/slices/navigationSlice";
import { restUser } from "../../redux/slices/userSlice";
import { getPatient } from "../../services/patientService";
import { AdminManageHeader } from "../page/admin/AdminManageHeader";
import { StorageHelper } from "../../helpers/StorageHelper";
import { ChangePassword } from "../ChangePassword";

const panelStyles: Partial<IPanelStyles> = {
    content: {
        overflowX: "hidden",
        touchAction: "true",
        height: "calc(100vh - 50px);",
        paddingLeft: 12,
        paddingRight: 12,
        paddingBottom: 0,
    },
};
export interface ISelectPatientPanelProps {
    isOpen: boolean;
    dismissPanel: () => void;
    childChangedClicked: () => void;
    adminClicked: () => void;
}

export const UserSettingsPanel: React.FunctionComponent<ISelectPatientPanelProps> = ({ isOpen, dismissPanel, childChangedClicked, adminClicked }: ISelectPatientPanelProps) => {
    const languageStrings = React.useContext(LanguageContext);

    const dispatch = useAppDispatch();

    const user = useAppSelector((state) => state.user.user);
    const selectedPatient = useAppSelector((state) => state.user.selectedPatient);

    const [persona, setPersona] = React.useState<IPersonaSharedProps>();

    const [isAdmin, setIsAdmin] = React.useState(false);
    const [hasMultiplePatients, setHasMultiplePatients] = React.useState(false);

    const [caretakers, setCaretakers] = React.useState<any[]>([]);
    const [gaurdians, setGaurdians] = React.useState<any[]>([]);
    const [medewerkers, setMedewerkers] = React.useState<any[]>([]);

    const [hidePasswordDialog, setHidePasswordDialog] = React.useState(true);

    React.useEffect(() => {
        if (user) {
            setPersona({
                imageUrl: user.profileImage && `data:image/*;base64,${user.profileImage}`,
                text: `${user?.firstName} ${user?.lastName}`,
                secondaryText: user?.email,
                // tertiaryText: user.roles.map((role) => role.name).join(", "),
                tertiaryText: `${user.typeId === UserType.Caretaker ? languageStrings.Caretaker : user.typeId === UserType.Gaurdian ? languageStrings.Parent : languageStrings.BrumBrumEmployee
                    } (${user.roles.map((role) => role.name).join(", ")})`,
            });

            const adminRole = user.roles.find((x) => x.id === 1);
            setIsAdmin(adminRole ? true : false);

            if (user.patients && user.patients.length > 1) {
                setHasMultiplePatients(true);
            }
        }
    }, [user]);

    React.useEffect(() => {
        (async () => {
            if (selectedPatient && user?.typeId === UserType.Gaurdian) {
                const patient = await getPatient(selectedPatient.id!.toString());

                setMedewerkers(
                    patient.users
                        .filter((x) => x.typeId === UserType.Medewerker)
                        .map((medewerker, index) => {
                            return (
                                <Persona
                                    imageUrl={medewerker.profileImage && `data:image/*;base64,${medewerker.profileImage}`}
                                    text={`${medewerker.firstName} ${medewerker.lastName}`}
                                    size={PersonaSize.size40}
                                    styles={{
                                        root: {
                                            marginTop: 10,
                                        },
                                    }}
                                    key={index}
                                />
                            );
                        })
                );

                setCaretakers(
                    patient.users
                        .filter((x) => x.typeId === UserType.Caretaker)
                        .map((caretaker, index) => {
                            return (
                                <Persona
                                    imageUrl={caretaker.profileImage && `data:image/*;base64,${caretaker.profileImage}`}
                                    text={`${caretaker.firstName} ${caretaker.lastName}`}
                                    size={PersonaSize.size40}
                                    styles={{
                                        root: {
                                            marginTop: 10,
                                        },
                                    }}
                                    key={index}
                                />
                            );
                        })
                );

                setGaurdians(
                    patient.users
                        .filter((x) => x.typeId === UserType.Gaurdian)
                        .map((gaurdian, index) => {
                            return (
                                <Persona
                                    imageUrl={gaurdian.profileImage && `data:image/*;base64,${gaurdian.profileImage}`}
                                    text={`${gaurdian.firstName} ${gaurdian.lastName}`}
                                    size={PersonaSize.size40}
                                    styles={{
                                        root: {
                                            marginTop: 10,
                                        },
                                    }}
                                    key={index}
                                />
                            );
                        })
                );
            }
        })();
    }, [selectedPatient]);

    const onLogout = () => {
        StorageHelper.removeItem("user");
        dispatch(restUser());
        dispatch(resetInsta());
        dispatch(resetNav());
        dispatch(setCurrentPage(AppPage.LoginPage));
    };

    return (
        <Panel
            headerText={languageStrings.Profile}
            isOpen={isOpen}
            onDismiss={dismissPanel}
            closeButtonAriaLabel="Close"
            type={PanelType.custom}
            customWidth={`${isMobile ? "100%" : "500px"}`}
            styles={panelStyles}
            allowTouchBodyScroll
        >
            <div className="mt-4">{persona && <Persona {...persona} size={PersonaSize.size72} />}</div>
            {isMobile && (
                <div className="mt-4">
                    {isAdmin && <ActionButton iconProps={{ iconName: "Settings" }} allowDisabledFocus onClick={async () => adminClicked()} text={languageStrings.Admin} />}
                    {hasMultiplePatients && <ActionButton iconProps={{ iconName: "ContactList" }} allowDisabledFocus onClick={async () => childChangedClicked()} text={languageStrings.ChangeChild} />}
                </div>
            )}
            {selectedPatient && user?.typeId === UserType.Gaurdian && (
                <div className="mt-6 px-4">
                    <div className="mb-4">
                        <AdminManageHeader title={languageStrings.BrumBrumEmployees} />
                        <div>
                            {medewerkers.length === 0 && <Label disabled>{languageStrings.NoMedewerkersFound}</Label>}
                            {medewerkers.length > 0 && medewerkers}
                        </div>
                    </div>
                    <div className="mb-4">
                        <AdminManageHeader title={languageStrings.Caretakers} />
                        <div>
                            {caretakers.length === 0 && <Label disabled>{languageStrings.NoCaretakersFound}</Label>}
                            {caretakers.length > 0 && caretakers}
                        </div>
                    </div>
                    <div className="mb-4">
                        <AdminManageHeader title={languageStrings.Parents} />
                        <div>
                            {gaurdians.length === 0 && <Label disabled>{languageStrings.NoParentsFound}</Label>}
                            {gaurdians.length > 0 && gaurdians}
                        </div>
                    </div>
                </div>
            )}
            <div className="mt-10 px-4">
                <DefaultButton text={"Change my password"} iconProps={{ iconName: "Edit" }} onClick={() => setHidePasswordDialog(false)} />
            </div>
            <div className="mt-5 px-4">
                <DefaultButton text={languageStrings.Logout} iconProps={{ iconName: "SignOut" }} onClick={() => onLogout()} />
            </div>
            <ChangePassword hideDialog={hidePasswordDialog} dismissDialog={() => setHidePasswordDialog(true)} userId={user!.id!} />
        </Panel>
    );
};
